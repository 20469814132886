import classNames from "classnames";
import React from "react";

const Gradient = ({
  children: content,
  reverse,
  from = "primary",
  to = "secondary",
}) => {
  const cx = classNames(
    {
      "from-primary": from === "primary",
      "from-secondary": from === "secondary",
      "from-tertiary": from === "tertiary",
    },
    {
      "to-primary": to === "primary",
      "to-secondary": to === "secondary",
      "to-tertiary": to === "tertiary",
    },
    "bg-clip-text text-transparent font-semibold",
    { "bg-gradient-to-r": !reverse, "bg-gradient-to-l": reverse }
  );
  return <span className={cx}>{content}</span>;
};

export default Gradient;
