import React from "react";
import Gradient from "../components/common/Gradient";
import Layout from "../components/Layout";

const ThankYouPage = () => {
  return (
    <Layout>
      <div className="container mx-auto text-center my-16">
        <h1 className="font-bold text-5xl mb-8">
          <Gradient reverse>Thank you</Gradient> for your message!
        </h1>
        <p className="text-lg">
          We've received your message, and someone will get back with you very
          quickly. Thank you!
        </p>
      </div>
    </Layout>
  );
};

export default ThankYouPage;
